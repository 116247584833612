import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
//import searchComp from '.././search-comp.vue'
import AddFavourite from "../../utility/js/addFav";
import commonApiService from "../../common/js/common-api-service";
import moment from "moment";
import commonPoService from "./common-po-service";
import listPoService from "./purchase-list-service";
import purchaseListService from "./purchase-list-service";
import exportLimit from "../../common/export-limit.vue";
export default {
  name: "purchaseList",
  data() {
    return {
      exportDialog: false,
      detailSearch: "",
      searchKey: "",
      searchObj: {},
      required: [(v) => !!v || "Field is required"],
      numberRule: [
        (v) => parseInt(v) > 0 || "Field should be greater than 0",
        (v) => parseInt(v) < 2147483647 || "Field should be less than 2,147,483,647",
      ],
      advSearch: "",
      drawer: true,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      showStartDate: true,
      showEndDate: true,
      advanceFilter: {
        Proj_Key: "",
        Business: "",
        ordType: [],
        FromDate: new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10),
        ToDate: new Date().toISOString().split("T")[0],
        Country: "",
        inStatus: "",
        outStatus: "",
        po_id: "",
        TopCount: 10,
      },
      FilterType: 1,
      totalRecords: 0,
      pageNumber: 1,
      excelName: "",
      // Table Headers
      listHeader: [
        {
          text: "Order Number",
          align: "start",
          value: "purno",
          class: "primary customwhite--text",
        },
        {
          text: "Vendor SO",
          value: "vendso",
          class: "primary customwhite--text",
        },
        {
          text: "Project",
          value: "project",
          class: "primary customwhite--text",
        },
        {
          text: "Outbound Status",
          value: "outbound_status",
          class: "primary customwhite--text",
        },
        {
          text: "Inbound Status",
          value: "inbound_status",
          class: "primary customwhite--text",
        },
        {
          text: "Created By",
          value: "created_by",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "order_date",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      draftHeader: [
        {
          text: "Project",
          value: "Project",
          class: "primary customwhite--text",
        },
        {
          text: "Business",
          value: "Business",
          class: "primary customwhite--text",
        },
        {
          text: "Created By",
          value: "CBy",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "CDate",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      bookmarkHeader: [
        {
          text: "Order Number",
          align: "start",
          value: "purno",
          class: "primary customwhite--text",
        },
        {
          text: "Project",
          align: "start",
          value: "project",
          class: "primary customwhite--text",
        },
        {
          text: "Business",
          align: "start",
          value: "business",
          class: "primary customwhite--text",
        },
        {
          text: "Created By",
          value: "cby",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "cdate",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
        },
      ],
      countryList: [],
      projectList: [],
      businessList: [],
      orderTypeList: [],
      outboundList: [],
      inboundList: [],
      searchList: [],
      orderDraftList: [],
      bookmarkList: [],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchResult: false,
      exportDisable: false,
      max3Rule: [(v) => (v || "").length >= 3 || "Min Length of 3 character", (v) => (v || "").length > 0 || "Min Length of 3 character"],
      // BreadCrumbs
      backSlash: true,
      actionList: [],
      export50kData: false,
      sDate: "",
      eDate: "",
      mainPage: "",
      favouriteList: [],
      pageUrl: [],
      showAdd: false,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      subPage: "",
      page: 1,
      pageCount: 1,
      bookmarktotalRecords: 0,
      draftTotalRecords: 0,
      // Flags:
      showDraft: true,
      showBkmark: true,
      isFormValid: false,
      deleteDialog: false,
      searchFlag: false,
      // For Export
      json_fields: {
        Order_Number: "purno",
        Vendor_SO: "vendso",
        Project: "project",
        Order_Date: "order_date",
        Outbound_Status: "outbound_status",
        Inbound_Status: "inbound_status",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    // Country API
    let countryData = await commonApiService.countryListNew(this.userId, "", 1, "get");
    this.countryList = countryData?.CountryInfo;
    //Project API
    let projectData = await commonApiService.getProject("get", this.userId);
    this.projectList = projectData?.ProjectInfo;
    let statusData = await commonPoService.getStatusData("get", this.userId);
    this.outboundList = statusData?.OutboundStatus;
    this.inboundList = statusData?.InBoundStatus;
    //DRaft Data
    let orderDraftData = await listPoService.getOrderDraftList("get", this.userId);
    this.orderDraftList = orderDraftData;
    this.showDraft = this.orderDraftList.length == undefined ? false : true;
    // if we want to show Draft Table
    if (this.showDraft) {
      // Converting the Date to MM/DD/YYYY/ HH:mm:ss Format
      this.orderDraftList.forEach((element) => {
        element.CDate = Utility.convertESTToLocal(element.CDate);
      });
    }
    this.draftTotalRecords = this.orderDraftList.length;
    this.showDraft = this.orderDraftList.length == undefined ? false : true;
    // Bookmark List
    let bookmarkData = await purchaseListService.getBookmarkList("get", this.userId);
    this.bookmarkList = bookmarkData;
    this.showBkmark = this.bookmarkList.length == undefined || Object.keys(this.bookmarkList).length === 0 ? false : true;
    if (this.showBkmark) {
      // Converting the Date to MM/DD/YYYY/ HH:mm:ss Format
      this.bookmarkList.forEach((element) => {
        element.cdate = Utility.convertESTToLocal(element.cdate);
      });
    }
    this.bookmarktotalRecords = this.bookmarkList.length;
  },
  mounted() {
    if (EncryptUtility.localStorageDecrypt("POSearch")) {
      this.searchObj = JSON.parse(EncryptUtility.localStorageDecrypt("POSearch"));
      this.searchResult = true;
      if (this.searchObj?.FilterType == 1) {
        /* eslint-disable no-mixed-spaces-and-tabs */
        this.searchKey = this.searchObj?.SearchStr;
        this.onClickSearch(this.searchKey, "search", this.searchObj?.PageNumber);
      }
      else if (this.searchObj?.FilterType == 3) {
        this.detailSearch = this.searchObj?.SearchStr;
        this.onClickSearch(this.detailSearch, "detailSearch", this.searchObj?.PageNumber);
      }
      else {
        this.advSearch = this.searchObj?.SearchStr;
        this.drawer = true;
        this.advanceFilter = JSON.parse(this.searchObj.AdvJson);
        this.sDate = this.advanceFilter.FromDate.substr(0, 10);
        this.eDate = this.advanceFilter.ToDate.substr(0, 10);
        this.onClickSearch(this.advSearch, "Advance", this.searchObj?.PageNumber);
        this.onChangeProject(this.advanceFilter.Proj_Key);
        this.setEndDate();
      }
    }
    this.$refs?.purchaseOrderForm?.resetValidation();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //to check if its a number of not
    isNumber(event) {
      let value = Utility.isNumber(event)
      return value
    },
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    // To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    // For End Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    // On Change Project in Advance Filter
    async onChangeProject(proj_id) {
      let obj = {
        UserId: this.userId,
        ProjKey: proj_id,
        BuKey: 0,
        IncludeEmptyBU: 0,
        WithinSameLocation: 0,
        loca_key: 0,
      };
      let businessData = await commonApiService.postBusinessData("post", obj);
      if (businessData.message != "NA") {
        this.businessList = businessData.map((x) => ({
          bu_id: x.Bu_id,
          business: x.Business,
        }));
      }
      // To get Order Type List
      let object = {
        UserId: this.userId,
        bu_key: 0, // DO not Delete
        pass: 3, // Fo not Delete
        project_key: proj_id,
      };
      let orderData = await commonPoService.getOrderTypeList("post", object);
      if (orderData?.OrderTypes) {
        this.orderTypeList = orderData?.OrderTypes?.map((x) => ({
          ord_code: x.ord_code,
          ordCodedesc: x.ordCodedesc,
        }));
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //File Name for Export
    fileName() {
      if (this.totalRecords <= 15000 || this.export50kData) this.exportFlag = true;
      this.excelName =
        "purchase_order_list_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    // Export API call
    // Export API
    async exportExcel() {
      this.exportDialog = false;
      if (this.totalRecords <= 15000) {
        let searchObject = {
          SearchStr: this.searchKey ? this.searchKey : this.advSearch,
          AdvJson: this.searchKey ? "" : JSON.stringify(this.advanceFilter),
          PageNumber: 1,
          userId: parseInt(this.userId),
          SearchType: 2,
          FilterType: this.searchKey ? 1 : 2,
        };
        const date = new Date();
        this.excelName =
          "purchaseOrderList_" +
          (1 + date.getMonth()).toString().padStart(2, "0") +
          "_" + // 9
          date.getDate().toString().padStart(2, "0") +
          "_" + // 30
          date.getFullYear() +
          ".xls";
        const response = await this.axios.post("/po/po_search", searchObject);
        let responseData = JSON.parse(response.data.body.message).Resultset;
        this.export50kData = false;
        return responseData;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Order Draft section: Delete Draft Orders
    onClickDraftDelete(item) {
      this.deleteMsg = "Are you sure you want to delete draft order?";
      this.deleteDialog = true;
      this.po_id = item.po_id;
      this.deleteItem = "Draft";
    },
    // Delete the So Draft
    async deletePODraft() {
      await purchaseListService.getDeleteDraftPO("get", this.userId, this.po_id, true);
      this.deleteDialog = false;
      // to Refresh the Table after Delete
      let orderDraftData = await purchaseListService.getOrderDraftList("get", this.userId);
      if (orderDraftData?.message == "NA") {
        this.draftTotalRecords = 0;
        this.showDraft = false;
      } else {
        this.orderDraftList = orderDraftData;
        this.orderDraftList.forEach((element) => {
          element.CDate = Utility.convertESTToLocal(element.CDate);
        });
        this.showDraft = this.orderDraftList.length == undefined ? false : true;
        this.draftTotalRecords = this.orderDraftList.length;
      }
    },
    //Delete All SO
    onClickAllDraftDelete() {
      this.deleteMsg = "Are you sure you want to delete all draft orders?";
      this.deleteDialog = true;
      this.deleteItem = "DraftAll";
    },
    //API call for Delete All SO
    async onClickDeleteAllDraft() {
      let draftData = await purchaseListService.getDeleteDraftPO("get", this.userId, 0, true);
      this.deleteDialog = false;
      if (draftData?.Result) {
        this.totalRecords = 0;
        this.showDraft = false;
        this.orderDraftList = [];
      }
    },

    // on Pagination change
    onChangePagination() {
      if (this.detailSearch) {
        this.onClickSearch(this.detailSearch, "detailSearch", this.page)
      }
      else if (this.searchKey) {
        this.onClickSearch(this.searchKey, "search", this.page)
      }
      else {
        this.onClickSearch(this.advSearch, "Advance", this.page)
      }
    },
    // onClick Search
    async onClickSearch(text, type, page) {
      this.detailSearch = "";
      this.exportDialog = false;
      this.searchList = [];
      this.totalRecords = 0;
      this.pageCount = 1;
      // to check if type is advance
      if (type == "Advance") {
        !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
        this.advanceFilter.FromDate = Utility.convertLocalToUTC(this.sDate);
        this.advanceFilter.ToDate = Utility.convertLocalToUTC(this.eDate);
        this.searchKey = "";
        // To check wether the call is from local or new
        if (!EncryptUtility.localStorageDecrypt("POSearch")) {
          // To check if form is valid or not
          if (this.$refs?.filterForm?.validate()) {
            this.exportDialog = false;
            this.page = page;
            type == "search" ? (this.searchKey = text) : (this.advSearch = text);
            this.searchResult = true;
            let obj = {
              UserId: this.userId,
              SearchStr: type == "search" ? this.searchKey : this.advSearch,
              PageNumber: page,
              AdvJson: type == "search" ? "" : JSON.stringify(this.advanceFilter),
              FilterType: type == "search" ? 1 : 2,
            };
            let searchData = await listPoService.getSearchResult("post", obj);
            this.$refs?.purchaseOrderForm?.resetValidation();
            if (searchData.TotalRecords > 0) {
              this.searchList = searchData?.Resultset.map((x) => ({
                po_id: x.po_id,
                purno: x.purno,
                vendso: x.vendso,
                project: x.project,
                order_date: x.order_date,
                outbound_status: x.outbound_status,
                inbound_status: x.inbound_status,
                order_type: x.order_type,
                stage_date: x.stage_date,
                created_by: x.created_by,
              }));
              this.searchList.forEach((element) => {
                element.order_date = Utility.convertESTToLocal(element.order_date);
              });
              EncryptUtility.localStorageEncrypt("POSearch", JSON.stringify(obj));
              this.totalRecords = searchData?.TotalRecords;
              this.pageCount = searchData?.TotalPages;
              this.exportDisable = false;
            }
            else {
              this.exportDisable = true;
              this.searchList = []
              this.pageCount = 0
              this.totalRecords = 0
              Utility.showNoDataToast()
              EncryptUtility.localStorageEncrypt("POSearch", "");

            }
          }
        } else {
          this.exportDialog = false;
          this.page = page;
          type == "search" ? (this.searchKey = text) : (this.advSearch = text);
          this.searchResult = true;
          let obj = {
            UserId: this.userId,
            SearchStr: type == "search" ? this.searchKey : this.advSearch,
            PageNumber: page,
            AdvJson: type == "search" ? "" : JSON.stringify(this.advanceFilter),
            FilterType: type == "search" ? 1 : 2,
          };
          let searchData = await listPoService.getSearchResult("post", obj);
          this.$refs?.purchaseOrderForm?.resetValidation();
          if (searchData.TotalRecords > 0) {
            this.searchList = searchData?.Resultset.map((x) => ({
              po_id: x.po_id,
              purno: x.purno,
              vendso: x.vendso,
              project: x.project,
              order_date: x.order_date,
              outbound_status: x.outbound_status,
              inbound_status: x.inbound_status,
              order_type: x.order_type,
              stage_date: x.stage_date,
              created_by: x.created_by,
            }));
            this.searchList.forEach((element) => {
              element.order_date = Utility.convertESTToLocal(element.order_date);
            });
            EncryptUtility.localStorageEncrypt("POSearch", JSON.stringify(obj));
            this.totalRecords = searchData?.TotalRecords;
            this.pageCount = searchData?.TotalPages;
            this.exportDisable = false;
          } else {
            this.exportDisable = true;
            this.searchList = []
            this.pageCount = 0
            this.totalRecords = 0
            Utility.showNoDataToast()
            EncryptUtility.localStorageEncrypt("POSearch", "");
          }
        }
      }
      else if (type == "detailSearch") {
        this.searchKey = "";
        this.detailSearch = text
        this.$refs?.purchaseOrderForm?.resetValidation();
        this.exportDialog = false;
        this.page = page;
        this.searchResult = true;
        let obj = {
          UserId: this.userId,
          SearchStr: text,
          PageNumber: page,
          AdvJson: "",
          FilterType: 3,
        };
        let searchData = await listPoService.getSearchResult("post", obj);
        if (searchData.TotalRecords > 0) {
          this.searchList = searchData?.Resultset.map((x) => ({
            po_id: x.po_id,
            purno: x.purno,
            vendso: x.vendso,
            project: x.project,
            order_date: x.order_date,
            outbound_status: x.outbound_status,
            inbound_status: x.inbound_status,
            order_type: x.order_type,
            stage_date: x.stage_date,
            created_by: x.created_by,
          }));
          this.searchList.forEach((element) => {
            element.order_date = Utility.convertESTToLocal(element.order_date);
          });
          EncryptUtility.localStorageEncrypt("POSearch", JSON.stringify(obj));
          this.totalRecords = searchData?.TotalRecords;
          this.pageCount = searchData?.TotalPages;
          this.exportDisable = false;
        }
        else {
          this.exportDisable = true
          this.searchList = []
          this.pageCount = 0
          this.totalRecords = 0
          Utility.showNoDataToast()
          EncryptUtility.localStorageEncrypt("POSearch", "");
        }
      }
      // else its a regular search
      else {
        this.exportDialog = false;
        this.page = page;
        type == "search" ? (this.searchKey = text) : (this.advSearch = text);
        this.searchResult = true;
        let obj = {
          UserId: this.userId,
          SearchStr: type == "search" ? this.searchKey : this.advSearch,
          PageNumber: page,
          AdvJson: type == "search" ? "" : JSON.stringify(this.advanceFilter),
          FilterType: type == "search" ? 1 : 2,
        };
        let searchData = await listPoService.getSearchResult("post", obj);
        this.$refs?.purchaseOrderForm?.resetValidation();
        this.$refs?.detailSearchForm?.resetValidation();
        if (searchData.TotalRecords > 0) {
          this.searchList = searchData?.Resultset.map((x) => ({
            po_id: x.po_id,
            purno: x.purno,
            vendso: x.vendso,
            project: x.project,
            order_date: x.order_date,
            outbound_status: x.outbound_status,
            inbound_status: x.inbound_status,
            order_type: x.order_type,
            stage_date: x.stage_date,
            created_by: x.created_by,
          }));
          this.searchList.forEach((element) => {
            element.order_date = Utility.convertESTToLocal(element.order_date);
          });
          EncryptUtility.localStorageEncrypt("POSearch", JSON.stringify(obj));
          this.totalRecords = searchData?.TotalRecords;
          this.pageCount = searchData?.TotalPages;
          this.exportDisable = false;
        }
        else {
          this.exportDisable = true
          this.searchList = []
          this.pageCount = 0
          this.totalRecords = 0
          Utility.showNoDataToast()
          EncryptUtility.localStorageEncrypt("POSearch", "");
        }
      }
    },
    // CLear Filter
    clearFilter() {
      this.searchList = []
      this.pageCount = 0
      this.totalRecords = 0
      EncryptUtility.localStorageEncrypt("POSearch", "");
      this.$refs.salesSearchForm?.resetValidation();
      this.$refs.detailSearchForm?.resetValidation();
      this.$refs.filterForm?.resetValidation();
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.detailSearch = "";
      this.advanceFilter = {
        Proj_Key: "",
        Business: "",
        ordType: [],
        FromDate: new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10),
        ToDate: new Date().toISOString().split("T")[0],
        Country: "",
        inStatus: "",
        outStatus: "",
        po_id: "",
        TopCount: 10,
      };
      this.advSearch = "";
      EncryptUtility.localStorageEncrypt("POSearch", "");
      this.maxStartDate = new Date().toISOString().split("T")[0];
      this.minEndDate = new Date().toISOString().split("T")[0];
      this.maxEndDate = new Date(new Date().getTime()).toISOString().split("T")[0];
    },
    //action is present or not Function
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    onBookmarkClickDelete(item) {
      this.deleteMsg = "Are you sure you want to remove bookmarked item?";
      this.deleteDialog = true;
      this.po_id = item.po_id;
      this.deleteItem = "BookMark";
    },
    //Bookmarked Order delete
    async deleteBookMarkDraft() {
      await purchaseListService.getDeleteBookmarkPO("get", this.userId, this.po_id, true);
      this.deleteDialog = false;
      // to Refresh the Table after Delete
      let bookmarkData = await purchaseListService.getBookmarkList("get", this.userId);
      if (bookmarkData?.message == "NA") {
        this.bookmarktotalRecords = 0;
        this.showBkmark = false;
      } else {
        this.bookmarkList = bookmarkData;
        this.bookmarkList.forEach((element) => {
          element.cdate = Utility.convertESTToLocal(element.cdate);
        });
        this.bookmarktotalRecords = this.bookmarkList.length;
        this.showBkmark = this.bookmarkList.length == undefined ? false : true;
      }
    },
    // Delete ALl Bookmark
    onBookmarkClickDeleteAll() {
      this.deleteMsg = "Are you sure you want to remove all bookmarked item?";
      this.deleteDialog = true;
      this.deleteItem = "BookMarkAll";
    },
    // APi Call to delete All Bookmark
    async onClickDeleteAllBookMark() {
      let bookmarkData = await purchaseListService.getDeleteAllBookmarkPO("get", this.userId, 0, true);
      this.deleteDialog = false;
      if (bookmarkData?.Result) {
        this.bookmarktotalRecords = 0;
        this.bookmarkList = [];
      }
    },
    // Redirect to SO details  View
    onClickBookmarkDetails(item) {
      let po_id = item.po_id;
      this.$router.push(`/purchase-order-details/${btoa(po_id.toString())}`);
    },
    //PO that is selected on s
    selectedPOData(item) {
      let po_id = item.po_id;
      this.$router.push(`/purchase-order-details/${btoa(po_id.toString())}`);
    },
    //On click of new purchase
    onClickNewPurchase() {
      this.$router.push(`/purchase-order-add`);
    },
    onClickPODetails(item) {
      let po_id = item.po_id;
      this.$router.push(`/purchase-order-add/${btoa(po_id.toString())}`);
    },
  },
  components: {
    breadcrumbComp,
    exportLimit,
  },
};
