import axios from 'axios';
import Utility from '../../../shared/utility';
import { store } from '../../../state-management/state-mgmt';
export default {
  // Add Starts here
  getSearchResult(call,obj) {
    return this.getData('/po/po_search', call, obj);
  },

  getOrderDraftList(call, userId) {
    return this.getData(`/po/po_temp_listbyid?UserId=${userId}&po_id=0&Pass=1`, call)
  },

  
  getOrderDraftById(call, userId, po_id) {
    return this.getData(`/po/po_temp_listbyid?UserId=${userId}&po_id=${po_id}&Pass=2`, call)
  },

  getDeleteDraftPO(call, userId, po_id, showMessage) {
    return this.getData(`/po/po_temp_delete?UserId=${userId}&po_id=${po_id}&Pass=2`, call, "", showMessage)
  },

  getBookmarkList(call, userId) {
    return this.getData(`/po/po_order_bookmark?UserId=${userId}&po_id=0&Pass=1`, call)
  },
  getDeleteBookmarkPO(call, userId, po_id, showMessage) {
    return this.getData(`/po/po_order_bookmark?UserId=${userId}&po_id=${po_id}&Pass=3`, call, "", showMessage)
  },
  getDeleteAllBookmarkPO(call, userId, po_id, showMessage) {
    return this.getData(`/po/po_order_bookmark?UserId=${userId}&po_id=${po_id}&Pass=4`, call, "", showMessage)
  },



  async getData(url, call, obj, showMessage, disableLoader) {
    if (!disableLoader) {
      var LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      }
      store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    }
    else {
      LoaderDialog = {
        visible: false,
        title: 'Please Wait...',
      }

    }
    let res = {};
    // Get Call
    if (call == 'get') {

      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: 'error',
            isShow: true,
            message: error.response.data.message,
          };
          store.commit('ConfigModule/Alert', Alert);
          LoaderDialog.visible = false;
          store.commit('ConfigModule/LoaderDialog', LoaderDialog);

        }
      });


    }
    // Post Calle
    else {

      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: 'error',
            isShow: true,
            message: error.response.data.message,
          };
          store.commit('ConfigModule/Alert', Alert);
          LoaderDialog.visible = false;
          store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        }
        return error
      });
    }

    if (res?.data?.body?.message != undefined || res?.data?.body?.message != null) {
      if (!res?.data?.body?.message) {
        let Alert = {
          type: 'error',
          isShow: true,
          message: Utility.apiError(res.data.body.message),
        };
        store.commit('ConfigModule/Alert', Alert);
        this.errorMessage = res.data.body.message;
        LoaderDialog.visible = false;
        store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: 'success',
            isShow: true,
            message: JSON.parse(res?.data?.body?.message).Result[0].Message,
          };
          store.commit('ConfigModule/Alert', Alert)
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse(res?.data?.body?.message);

      } catch (err) {
        let Alert = {
          type: 'error',
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit('ConfigModule/Alert', Alert);
        this.errorMessage = err.message;
        return JSON.parse(res?.data?.body?.message);

      }
    }
    else if (res?.message) {
      return res?.response?.status
    }

    else if (res.data.body.message == null) {
      
      LoaderDialog.visible = false;
      store.commit("ConfigModule/LoaderDialog", LoaderDialog);

      return JSON.parse('{"message":"NA"}');
    }
    else {
      if (!res?.data?.message) {
        let Alert = {
          type: 'error',
          isShow: true,
          message: Utility.apiError(res?.data?.message),
        };
        store.commit('ConfigModule/Alert', Alert);
        this.errorMessage = res?.data?.message;
        LoaderDialog.visible = false;
        store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        return {};
      }
      try {
        if (showMessage) {
          let Alert = {
            type: 'success',
            isShow: true,
            message: res.data.message,
          };
          store.commit('ConfigModule/Alert', Alert)
        }
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);

        return JSON.parse(res?.data?.message);
      } catch (err) {
        let Alert = {
          type: 'error',
          isShow: true,
          message: Utility.apiError(err),
        };
        store.commit('ConfigModule/Alert', Alert);
        this.errorMessage = err.message;
        return {};
      }
    }

  },
};


